import React from "react"
import Layout from "../components/layout"
import Img from "gatsby-image"
import {graphql} from "gatsby";
import Partner from "../components/Partner";
import SEO from "../components/seo";

export default ({data: {datoCmsPartner: partner, datoCmsPartnerspage: page}}) => {
  return (
    <Layout>
      <SEO title={`Partner ${partner.name}`} seo={partner.seoMetaTags}/>
      <Img fluid={page.image.fluid}/>
      <div className="flex flex-col justify-center items-center lg:mx-32 mx-2 my-8">
        <h1 className='font-title tracking-wide title text-black text-center text-base my-4 md:my-8'>{partner.name}</h1>
        <div className="flex flex-wrap md:flex-no-wrap justify-center items-center">
          {partner.logo ? <Img className='md:mx-4 my-4' style={{minWidth: 250}} fixed={partner.logo.fixed}/> : <div/>}
          <span className="my-4 font-sans font-light text-black text-lg"
                dangerouslySetInnerHTML={{__html: partner.description}}/>
        </div>
        <h1 className='font-title tracking-wide title text-black text-center text-base my-2 md:my-4'>Other Partners</h1>
      </div>
      <div className="flex flex-wrap justify-center mb-16">
        {page.otherpartners.map((p) => <Partner key={p.id} p={p}/>
        )}
      </div>
    </Layout>
  )
}

export const query = graphql`
    query Partner($slug:String)  {
        datoCmsPartner(slug:{eq:$slug}) {
            seoMetaTags {
                ...GatsbyDatoCmsSeoMetaTags
            }
            id
            name
            description
            logo {
                fixed(width:250, imgixParams: { auto: "compress,format" }) {
                    ...GatsbyDatoCmsFixed
                }
            }
        }
        datoCmsPartnerspage {
            image {
                fluid(maxWidth: 1500, imgixParams: {fit: "crop", w:"1000", h:"300", crop:"top"}) {
                    ...GatsbyDatoCmsFluid
                }
            }
            otherpartners {
                logo {
                    fixed(width:180, height:180, imgixParams: { auto: "compress,format" }) {
                        ...GatsbyDatoCmsFixed
                    }
                }
                slug
            }
        }
    }
`
