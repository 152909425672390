import {Link} from "gatsby";
import Img from "gatsby-image";
import React from "react";

const Partner = ({p}) => <Link
  className='m-2 md:m-8 border border-yellow-700 z-50 p-2 flex items-center justify-center'
  style={{width: 230, height: 230}}
  to={`/partners/${p.slug}/`}>
  {p.logo ? <Img fixed={p.logo.fixed} className='z-0 '/> : <div style={{width: 80}}/>}
</Link>

export default Partner